@import '../../styles/customMediaQueries.css';

.root {
}

.pageRoot {
  padding-bottom: 90px;

  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  margin: 24px;
  color: var(--colorFail);
}

.longWord {
  hyphens: auto;
  word-break: break-all;
  word-break: break-word;
}

.sectionHero {
  margin: 0 0 36px 0;
  position: relative;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.heroHeight {
  @media (--viewportMedium) {
    max-height: 50vh;
    overflow-y: hidden;
    height: calc(0.41 * 100vw);
  }
}

.imageWrapperForSectionHero {
  composes: heroHeight;
  background-color: var(--colorGrey100);

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 8px;
  color: var(--colorGrey100);
  background-color: var(--colorGrey700);

  /* Action bar prevents the image click events going to the parent and should not show a pointer */
  cursor: initial;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.actionBarCustom {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  /* Action bar prevents the image click events
  going to the parent and should not show a pointer */
  cursor: initial;
}

.actionBarForProductLayout {
  margin: 24px;
  width: calc(100% - 48px);

  @media (--viewportMedium) {
    width: 100%;
    margin: 24px 0 24px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionBarContainerForHeroLayout {
  top: 13px;
  left: 13px;
  position: absolute;
  width: calc(100% - 26px);

  gap: 24px;
  display: flex;
  flex-direction: column;
}

.actionBarForHeroLayout {
  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  composes: h5 from global;
  margin: 14px 24px;

  @media (--viewportMedium) {
    margin: 24px 12px;
  }
}

.ownListingTextPendingApproval {
  color: var(--colorAttention);
}

.closedListingText {
  composes: h4 from global;
  text-align: center;

  width: 100%;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.addPayoutDetails,
.editListingLink {
  composes: h5 from global;
  margin: 14px 24px 11px 24px;

  flex-shrink: 0;
  color: var(--colorGrey100);
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorWhite);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin: 24px 24px 24px 12px;
  }
}

.editListingLinkCustom {
  gap: 4px;
  display: flex;
  align-items: center;

  & span {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  &:hover {
    text-decoration: none;
  }
}

.missingPayoutDetailsText {
  color: var(--colorAttention);
}

.payoutDetailsWarning {
  gap: 24px;
  display: flex;
  flex-direction: column;
}

.editIcon {
  margin: -6px 7px 0 0;
}

.editIconCustom {
  fill: transparent;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  margin: 0;
  right: 24px;
  bottom: 19px;
  position: absolute;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--colorWhite);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
    position: relative;

    display: flex;
    max-width: var(--contentMaxWidth);
  }

  @media (--viewportLarge) {
    padding: 0 36px;
    margin: 0 auto 117px;
    max-width: calc(var(--contentMaxWidth) + 72px);
  }

  @media (--viewportXLarge) {
    padding: 0;
    max-width: var(--contentMaxWidth);
  }
}

.mainColumnForProductLayout {
  width: 100%;
  /* background-color: #20639b; */

  @media (--viewportLarge) {
    width: 50%;
  }
}

.orderColumnForProductLayout {
  width: 100%;
  /* background-color: #ed553b; */

  @media (--viewportLarge) {
    width: 50%;
  }
}

.responsiveContainer {
  composes: globalResponsiveContainer from global;
  position: relative;

  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.backButtonCustom {
  margin-bottom: 0;
}

.backAndEditButton {
  margin-bottom: 3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .contentWrapperForProductLayout {
  width: 100%;
  padding: 24px 0px;
  position: relative;
  margin: 10px auto 10px auto;
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-right: var(--bs-gutter-x, 0.75rem);

  display: flex;
  flex-direction: column;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
    padding: 40px 0px;
    margin: 0px auto 0px auto;
  }

  @media (min-width: 992px) {
    max-width: 960px;
    padding: 56px 0px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
    margin: 0px auto 0px auto;
  }

  @media (min-width: 1400px) {
    max-width: 1320px;
  }

  @media (--viewportXLarge) {
    max-width: 1440px;
  }
} */

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    flex-shrink: 0;
    margin-top: 50px;
    margin-bottom: 51px;
  }

  @media (--viewportLarge) {
    margin-top: 80px;
    margin-bottom: 0px;

    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    flex-grow: 1;
    flex-shrink: 0;
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    display: block;
    flex-shrink: 0;
    margin-top: 80px;
    margin-left: 60px;
    flex-basis: 312px;
    padding-left: 60px;
    border-left: 1px solid var(--colorGrey100);
  }
}

.orderPanel {
  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    top: 108px;
    position: sticky;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    top: 140px;
    display: block;
    position: sticky;
  }

  /* Note: panel height might be too much on small viewport - hence min-height */
  @media (--viewportLarge) and (min-height: 950px) {
    top: 140px;
  }

  @media (--vwFigma) {
    top: 92px;
  }
}

.orderPanelTitle {
  /* Font */
  font-weight: var(--fontWeightBold);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionAvatar {
  /* Position (over the listing image)*/
  width: 60px;
  margin-top: -31px;
  margin-left: 24px;
  position: relative;

  @media (--viewportMedium) {
    top: -49px;
    margin-top: 0;
    margin-left: 0;
    position: absolute;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    /* Hide the medium avatar from the bigger screens */
    display: none;
  }
}

.avatarDesktop {
  /* Hide the large avatar from the smaller screens */
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    margin: 0;
    padding: 0;

    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

.desktopPriceValue {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  composes: h5 from global;
  color: var(--colorGrey700);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  padding: 0 24px;
  margin-bottom: 33px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.title {
  /* Font */
  composes: marketplaceModalTitleStyles from global;
  color: var(--colorGrey700);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  composes: h5 from global;

  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--colorGrey700);
  }
}

.authorNameLink {
  composes: textSmall from global;
  color: var(--colorGrey700);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Colors */
  color: var(--marketplaceColor);

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
  }
}

.sectionText {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.text {
  margin: 0;
  padding: 1px 0 5px 0;
  font-weight: var(--fontWeightRegular);

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
    padding: 3px 0 5px 0;
  }
}

.ingress {
  composes: p from global;
  margin: 0;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.sectionDetails,
.sectionMultiEnum {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.mapContainer {
  height: 640px;
  /* background-color: #ed553b; */

  @media (--viewportMedium) {
    height: 320px;
  }
}

.sectionMap {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  gap: 6px;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;

  margin: 0;
  padding: 0;
  margin-bottom: 12px;

  gap: 6px;
  display: flex;
  cursor: pointer;
  font-weight: var(--fontWeightBold);

  & svg {
    min-width: 1rem;
    min-height: 1rem;
  }

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  display: block;
  cursor: pointer;
  overflow-y: hidden;
  /* background-color: black; */

  /* Static map: dimensions are 640px */
  /* max-width: 640px;
  max-height: 640px; */

  & > div {
    width: 100%;
    height: 100%;
    max-width: 100% !important;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.sectionAuthor,
.sectionReviews {
  padding: 0;
  margin-top: 3rem;
  scroll-margin-top: calc(var(--topbarHeight) + 24px);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 3rem;
    scroll-margin-top: calc(var(--topbarHeightDesktop) + 24px);
  }

  @media (--viewportLarge) {
    padding: 0px;
  }
}

.sectionProviderReviews {
  margin-top: 0 !important;
}

.providerReviews {
  margin-top: 0 !important;
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--colorGrey100);
  }

  @media (--viewportMedium) {
    padding: 5px 0 2px 0;
    line-height: 32px;
  }
}

.detailLabel {
  letter-spacing: -0.09px;
  font-weight: var(--fontWeightSemiBold);
}

.inquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.inquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
    display: flex;
    flex-direction: column;
  }
}

.inquirySubmitButtonWrapper {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px;
  position: fixed;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    padding: 0;
    margin-top: auto;
    position: static;
  }
}

.longWord {
  hyphens: auto;
  word-break: break-word;
}

.productGallery {
  margin: 0 0 36px 0;

  @media (--viewportMedium) {
    margin: 24px 0 48px 0;
  }

  @media (--viewportLarge) {
    margin: 0 0 40px 0;
    padding-bottom: 4px;
  }
}

.mobileHeading {
  /* margin: 0 24px 24px 24px; */

  @media (--viewportMedium) {
    /* margin: 0 0 24px 0; */
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-shrink: 0;
    flex-basis: 312px;
  }
}

.backButton {
  display: flex;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: #9d9d9d;
  margin-bottom: 1rem;
  text-decoration: underline;
  /* background-color: red; */

  &:hover {
    text-decoration: none;
  }
}

.percentageOuterDiv {
  width: 80px;
  height: 80px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    width: 80%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #fff;
    border-radius: 50%;
    border: 8px solid transparent;
  }
}

.ratingHead {
  padding: 20px;
  background-color: #f5f5f5;

  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    gap: 2rem;
    flex-direction: column;
    justify-content: center;
  }
}

.ratingHead1 {
  flex-basis: 30%;
  /* background-color: blue; */

  & h3 {
    font-size: 25px;
    font-weight: 600;
    color: #23232c;
    white-space: nowrap;

    @media (max-width: 575px) {
      font-size: 18px;
      font-weight: 600;
      white-space: unset;
    }
  }

  & > div {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > div {
      background-color: #3caea3;
      padding: 4px 8px;

      & svg {
        width: 25px;
        height: 25px;
        fill: #fff;
      }
    }

    & > p {
      margin: 0;
      font-size: 25px;
      font-weight: 700;
      color: #3caea3;

      & span {
        font-size: 16px;
        color: #23232c;
      }
    }
  }

  & > p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #23232c;

    & > span {
      font-weight: 700;
    }

    @media (max-width: 767px) {
      margin-top: 8px;
    }
  }
}

.circularProgressDiv {
  gap: 35px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* background-color: violet; */
}

.replyDv {
  padding-left: 10%;
}

.reply {
  & > p:last-child {
    margin: 0 0 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
}

.noReply {
  display: none;
}

.mainContent {
  gap: 3rem;
  display: flex;
  flex-direction: column;
  /* background-color: aquamarine; */

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.starPercentCont {
  gap: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;

  & span {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 18px;
    font-weight: 700;
    color: #23232c;
  }
}

.reviews {
  display: flex;
  flex-direction: column;
  margin-top: 35px;
  gap: 27px;

  & > div:last-child {
    border: 0 !important;
  }
}

.review {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cecece;
}

.reviewStars {
  display: flex;
  align-items: center;
  gap: 2px;

  & svg {
    width: 20px;
    height: 20px;
    fill: #3caea3;
  }
}

.reviewerInfo {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  color: #23232c;

  & span {
    color: #9d9d9d;
  }
}

.reviewStarsDiv {
  gap: 8px;
  display: flex;
  align-items: center;

  & > span {
    font-size: 18px;
    font-weight: 700;
    color: #3caea3;
  }
}

.reviewListingNamep {
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  margin-left: auto;
  color: var(--colorOrange);

  &:hover {
    text-decoration: underline;
  }
}

.datep {
  font-size: 10px;
  font-weight: 500;
  color: #9d9d9d;
  margin: 0;
}

.reviewContent {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

.replyBtn {
  padding: 0;
  margin-right: auto;

  font-size: 16px;
  font-weight: 500;

  border: 0;
  cursor: pointer;
  color: #20639b;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.wrtRevBtn {
  display: block;
  margin: 15px 0 0 auto;

  border: 0;
  cursor: pointer;
  text-decoration: underline;
  color: var(--marketplaceColor);

  &:hover {
    text-decoration: none;
  }
}

.rplyClose {
  border: 0;
  color: red;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
}

.sendReply {
  padding: 5px 10px;
  border-radius: 5px;

  border: 0;
  color: #fff;
  cursor: pointer;
  background-color: #20639b;
}

.replyBtndv {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.replyDv {
  margin-top: 20px;
}

.rplyInfoSpn {
  margin-top: 20px;
}

.rplyLoad {
}

.rplyErr {
  color: red;
  font-weight: 700;
}

.moreReviews {
  display: block;
  margin: 30px auto;
  padding: 15px 25px;

  border: 0;
  cursor: pointer;
  border-radius: 8px;
  color: var(--marketplaceColor);
  background-color: var(--colorLightGray);
}

/* Custom css for similar listings */
.similarListingPanel {
  margin-top: 3rem;

  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  /* background-color: red; */

  @media (max-width: 767px) {
    margin-top: 3rem;
  }
}

.header {
  margin: 0;
  padding: 0;
  color: var(--colorOrange);
  font-family: var(--fontFamilyRoboto);
}

/* .listingCards {
  width: 100%;
  margin: 0 auto;
  padding: 0rem 0.75rem;

  gap: 24px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (--vwS) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--vwM) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--vwL) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (--vwXL) {
    grid-template-columns: repeat(5, 1fr);
  }
} */

.similarListingCards {
  gap: 40px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 1200px) {
    row-gap: 40px;
    column-gap: 34px;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 1fr);
  }
}

.noSimilarListing {
  margin: 0;
  padding: 0;
  opacity: 0.4;
  color: var(--colorTextLightGray);
  font-family: var(--fontFamilyRoboto);
}

.buttonsWrapper {
  display: flex;
  justify-content: center;
}

.viewMore {
  min-height: 48px;
  width: fit-content;

  /* Font */
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  /* Button borders */
  border: none;
  border-radius: 8px;

  /* Button colors */
  color: var(--colorTextGray);
  background-color: var(--colorLightGray);
  border: 1px solid var(--colorTextLightGray);

  & a {
    min-height: 48px;
    width: fit-content;
    padding: 12px 16px;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    box-shadow: none;
    background-color: var(--colorGrey200);
  }
}
/* Custom css for similar listings */
